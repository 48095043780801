<template>
  <div>
    <b-row class="m-0">
      <b-col class="content-header" cols="12">
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }}
          <span class="text-danger" v-show="archive">&nbsp;(archive)</span>
        </h2>
        <div class="content-header-search mr-1">
          <b-form-input id="searchPool" v-model="searchPool" placeholder="Rechercher un bassin" v-on:input="onFilterTextBoxChanged()" class="w-full" />
        </div>
        <div class="content-header-actions">
          <b-button variant="primary" @click="newPool('')" class="d-flex px-1 mr-1">
            <feather-icon icon="PlusIcon" />
            <span class="d-none d-md-block">Nouveau</span>
          </b-button>
          <b-dropdown class="bg-outline-primary btn-group-icon" variant="outline-primary">
            <template #button-content>
              <span class="material-icons-outlined m-0 p-0">settings</span>
            </template>
            <b-dropdown-item class="w-full border-bottom-grey-light" @click="archive = !archive">{{
              !archive ? "Voir les bassins archiv&eacute;s" : "Voir les bassins actifs"
            }}</b-dropdown-item>
            <b-dropdown-item class="w-full border-bottom-grey-light" @click="togglePanelColumn">{{
              this.gridOptions.sideBar == null ? "Gérer les colonnes" : "Masquer la gestion des colonnes"
            }}</b-dropdown-item>
            <b-dropdown-item class="w-full" @click="exportDataCSV">Exporter en CSV</b-dropdown-item>
            <b-dropdown-item class="w-full" @click="exportDataXLS">Exporter en XLS</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <div class="content-grid">
      <div class="text-center flex-center" v-if="isLoadingPoolsList">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          {{ "Chargement en cours de la liste des bassins..." }}
        </div>
      </div>
      <ag-grid-vue
        v-else
        style="width: 100%; height: 100%"
        class="ag-theme-material"
        :class="archive ? 'ag-archived' : ''"
        :rowData="gridData"
        :gridOptions="gridOptions"
        :key="filter"
        @grid-ready="onGridReady"
        @rowClicked="onRowClicked"
      >
      </ag-grid-vue>
    </div>
    <sidebar-pool ref="sidebarPool" />
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import { agGridLocaleFR } from "@/data/agGridLocaleFR";

import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";

import SidebarPool from "@/components/pool/SidebarPool";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

export default {
  components: {
    BButton,
    AgGridVue,
    SidebarPool,
  },
  directives: {
    Ripple,
  },
  props: {
    poolId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      archive: false,
      filter: false,
      sidebar: false,
      activeSidebarPool: false,
      optionSeaWeeds: [
        {
          label: "Aucune",
          value: 1,
        },
        {
          label: "Modérée",
          value: 2,
        },
        {
          label: "Elevée",
          value: 3,
        },
      ],
      optionConditions: [
        {
          label: "Catastrophique",
          value: 1,
        },
        {
          label: "Mauvais état",
          value: 2,
        },
        {
          label: "État Moyen",
          value: 3,
        },
        {
          label: "Bon état",
          value: 4,
        },
        {
          label: "Impéccable",
          value: 5,
        },
      ],
      gridOptions: {
        localeText: agGridLocaleFR,
        columnDefs: null,
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          editable: false,
        },
        enableRangeSelection: true,
        pagination: true,
        paginationPageSize: 20,
      },
      gridApi: null,
      columnApi: null,
      searchPool: "",
    };
  },
  computed: {
    ...mapGetters(["customersList", "poolsList", "poolsListArchived", "isLoadingPoolsList", "isOpenSidebarPool"]),
    gridData() {
      if (this.archive) {
        return this.poolsListArchived;
      } else {
        return this.poolsList;
      }
    },
  },
  beforeMount() {
    this.gridOptions.columnDefs = [
      {
        field: "customerId",
        headerName: "Propriétaire",
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 160,
        cellRenderer: (params) => {
          return this.findCustomers(params.value);
        },
        getQuickFilterText: (params) => {
          return this.findCustomers(params.value);
        },
      },
      {
        field: "label",
        headerName: "Nom",
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 160,
      },
      {
        field: "city",
        headerName: "Ville",
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 160,
      },
      {
        field: "postalCode",
        headerName: "Code postal",
        resizable: true,
        sortable: true,
        minWidth: 160,
        initialHide: true,
      },
      {
        field: "address",
        headerName: "Adresse",
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 160,
      },
      {
        field: "poolType.label",
        headerName: "Type",
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 160,
      },
      {
        field: "length",
        headerName: "longueur",
        resizable: true,
        sortable: true,
        minWidth: 160,
        initialHide: true,
        cellRenderer: (params) => {
          return params.value + "m";
        },
      },
      {
        field: "width",
        headerName: "largeur",
        resizable: true,
        sortable: true,
        minWidth: 160,
        initialHide: true,
        cellRenderer: (params) => {
          return params.value + "m";
        },
      },
      {
        field: "depth",
        headerName: "Profondeur",
        resizable: true,
        sortable: true,
        minWidth: 160,
        initialHide: true,
        cellRenderer: (params) => {
          return params.value + "m";
        },
      },
      {
        headerName: "Volume",
        resizable: true,
        sortable: true,
        minWidth: 160,
        initialHide: true,
        cellRenderer: (params) => {
          if (params.data.length && params.data.width && params.data.depth) {
            return `${(params.data.length * params.data.width * (params.data.depth / 100)).toFixed(2)} m³`;
          } else {
            return "";
          }
        },
      },
      {
        field: "condition",
        headerName: "Condition",
        resizable: true,
        sortable: true,
        minWidth: 160,
        initialHide: true,
        cellRenderer: (params) => {
          return getCustomerName(params.value);
        },
      },
      {
        field: "seaWeeds",
        headerName: "Végétation",
        resizable: true,
        sortable: true,
        minWidth: 160,
        initialHide: true,
        cellRenderer: (params) => {
          return this.findSeaWeeds(params.value);
        },
      },
      {
        field: "long",
        headerName: "Longitude",
        resizable: true,
        sortable: true,
        minWidth: 160,
        initialHide: true,
        cellRenderer: (params) => {
          return params.value + "°";
        },
      },
      {
        field: "lat",
        headerName: "Latitude",
        resizable: true,
        sortable: true,
        minWidth: 160,
        initialHide: true,
        cellRenderer: (params) => {
          return params.value + "°";
        },
      },
      {
        field: "indications",
        headerName: "Indications",
        resizable: true,
        sortable: true,
        minWidth: 160,
        initialHide: true,
      },
      {
        field: "hasAlarmSystem",
        headerName: "Alarme",
        resizable: true,
        sortable: true,
        minWidth: 160,
        cellRenderer: (params) => {
          return params.value === true ? "Oui" : "Non";
        },
        initialHide: true,
      },
      {
        field: "hasFenceWithGate",
        headerName: "Barrière avec porte",
        resizable: true,
        sortable: true,
        minWidth: 160,
        cellRenderer: (params) => {
          return params.value === true ? "Oui" : "Non";
        },
        initialHide: true,
      },
      {
        field: "hasCurtain",
        headerName: "Rideau",
        resizable: true,
        sortable: true,
        minWidth: 160,
        cellRenderer: (params) => {
          return params.value === true ? "Oui" : "Non";
        },
        initialHide: true,
      },
      {
        field: "hasJetSwim",
        headerName: "Nage contre courant",
        resizable: true,
        sortable: true,
        minWidth: 160,
        cellRenderer: (params) => {
          return params.value === true ? "Oui" : "Non";
        },
        initialHide: true,
      },
      {
        field: "hasCleaningRobot",
        headerName: "Robot nettoyeur",
        resizable: true,
        sortable: true,
        minWidth: 160,
        cellRenderer: (params) => {
          return params.value === true ? "Oui" : "Non";
        },
        initialHide: true,
      },
      {
        field: "hasAntiFrostSystem",
        headerName: "Anti froid",
        resizable: true,
        sortable: true,
        minWidth: 160,
        cellRenderer: (params) => {
          return params.value === true ? "Oui" : "Non";
        },
        initialHide: true,
      },
      {
        field: "poolType.label",
        headerName: "Forme",
        resizable: true,
        sortable: true,
        minWidth: 160,
        initialHide: true,
      },
      {
        field: "poolAccessType.label",
        headerName: "Accés",
        resizable: true,
        sortable: true,
        minWidth: 160,
        initialHide: true,
      },
      {
        field: "accessMethodType.label",
        headerName: "Méthode d'accés",
        resizable: true,
        sortable: true,
        minWidth: 160,
        initialHide: true,
      },
      {
        field: "contractType.label",
        headerName: "Contrat",
        resizable: true,
        sortable: true,
        minWidth: 160,
        initialHide: true,
      },
      {
        field: "waterTreatmentType.label",
        headerName: "Traitement",
        resizable: true,
        sortable: true,
        minWidth: 160,
        initialHide: true,
      },
      {
        field: "customerId",
        headerName: "Client",
        resizable: true,
        sortable: true,
        initialHide: true,
        minWidth: 160,
        cellRenderer: (params) => {
          return this.findCustomers(params.value);
        },
      },
    ];
  },
  created() {
    this.fetchCustomersList().then(() => {
      this.gridColumnApi.setColumnsVisible(["customerId"], true);
    });
    this.fetchPoolsList();
    this.fetchPoolsListArchived();
  },
  mounted() {
    if (this.poolId > 0) {
      this.onRowClicked({ data: { id: this.poolId } });
    }
  },
  watch: {
    poolId(val) {
      if (val > 0) {
        this.onRowClicked({ data: { id: this.poolId } });
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchCustomersList",
      "fetchPoolsList",
      "fetchPoolsListArchived",
      "deletePool",
      "fetchDevicesListByPoolId",
      "fetchProductUsedOnTimeSpanByPoolId",
    ]),

    // Gestion d'AgGrid
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridApi.getColumnDefs().forEach((column) => {
        allColumnIds.push(column.field);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setHeaderHeight(36);
    },
    findSeaWeeds(id) {
      const seaWeed = this.optionSeaWeeds.find((elem) => elem.value === id);
      if (seaWeed) {
        return seaWeed.label;
      } else return "";
    },
    findConditions(id) {
      const condition = this.optionConditions.find((elem) => elem.value === id);
      if (condition) {
        return condition.label;
      } else return "";
    },
    findCustomers(id) {
      const customer = this.customersList.find((elem) => elem.id == id);
      if (customer) {
        return customer.lastName + " " + customer.firstName;
      } else {
        return "";
      }
    },
    onRowClicked(event) {
      if (this.archive) {
        this.$refs["sidebarPool"].getPoolArchivedDetails(event.data.id, true);
      } else {
        this.$refs["sidebarPool"].getPoolDetails(event.data.id);
      }

      this.fetchProductUsedOnTimeSpanByPoolId({
        poolId: event.data.id,
        startTo: dayjs().startOf("month").format("YYYY-MM-DD"),
        endTo: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
      this.fetchDevicesListByPoolId(event.data.id);
      this.$store.commit("SET_IS_OPEN_SIDEBAR_POOL", true);
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchPool);
    },
    togglePanelColumn() {
      if (!this.gridOptions.sideBar || this.gridOptions.sideBar == null) {
        this.gridOptions.sideBar = {
          toolPanels: [
            {
              id: "columns",
              labelDefault: "Colonnes",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: "columns",
        };
        this.filter = true;
      } else {
        this.gridOptions.sideBar = null;
        this.filter = false;
      }
    },
    exportDataXLS() {
      this.gridApi.exportDataAsExcel();
    },
    exportDataCSV() {
      this.gridApi.exportDataAsCsv();
    },
    newPool() {
      this.$router.push({ path: "/new-pool" });
    },
  },
};
</script>
