b
<template lang="pug">
vs-sidebar.items-no-padding(
  position-right="",
  v-model="active",
  parent="body",
  default-index="55555551",
  :click-not-close="true"
)
  .h-100(
    v-if="isLoadingPool || isUpdatingPool || isDeletingPool || isLoadingCustomer"
  )
    .text-center.flex-center.h-100
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement des d&eacute;tails du bassin...
  .h-full(v-else-if="pool")
    .sidebar-header.py-1.px-2
      .d-flex.align-items-center
        div
          h3 {{ pool.label }}
          small {{ pool.activity }}
      feather-icon.close-icon.cursor-pointer(
        icon="XIcon",
        size="24",
        @click.stop="active = !active"
      )
    vs-divider.m-0
    .sidebar-action.py-05.px-2
      .d-flex.align-items-center
        b-button.btn-icon(
          v-if="archived",
          variant="warning",
          @click="restorePoolLocal(pool.id, pool.label)"
        )
          feather-icon(icon="ArrowDownIcon")
          | Restaurer
        b-button.btn-icon(
          v-else,
          variant="primary",
          @click="editPool(pool.id)",
          :disabled="archived"
        )
          feather-icon(icon="EditIcon")
          | Modifier
      b-button-group
        b-button.btn-icon(
          variant="outline-danger",
          @click="deletePoolLocal(pool.id, pool.label)",
          v-if="archived"
        )
          feather-icon.mr-50(icon="Trash2Icon")
          | Supprimer d&eacute;finitivement
        b-button.btn-icon(
          v-else,
          variant="outline-danger",
          @click="archivePoolLocal(pool.id, pool.label)"
        )
          feather-icon(icon="ArchiveIcon")
    vs-divider.m-0
    .sidebar-content
      b-row.content-scrollable-sticky.m-0.justify-content-center
        b-col#nav-sticky-horizontal.content-scrollable-sticky-inner(
          cols="12",
          style="padding: 0 !important; height: 100% !important"
        )
          ul.d-flex
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(
                :class="tabActive == 'pool' ? 'current' : ''",
                @click="tabActive = 'pool'"
              )
                span Bassin
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(
                :class="tabActive == 'devices' ? 'current' : ''",
                @click="tabActive = 'devices'"
              )
                span Appareils
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(
                :class="tabActive == 'products' ? 'current' : ''",
                @click="tabActive = 'products'"
              )
                span Produits
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(
                :class="tabActive == 'lastAnalysis' ? 'current' : ''",
                @click="tabActive = 'lastAnalysis'"
              )
                span Dernières Analyses
      .pool(
        v-show="pool.secondaryPhoneNumber || pool.phoneNumber || pool.label",
        v-if="tabActive == 'pool' && pool.id"
      )
        h4.mb-1.text-primary
          feather-icon.mr-1(icon="InfoIcon")
          | Informations
        ul.listTab
          li(v-if="pool.label")
            span Nom du Bassin
            span {{ pool.label }}
          li(v-if="customer")
            span Client
            span {{  customer.firstName+&apos; &apos;+customer.lastName  }}
          li(v-if="pool.poolType && pool.poolType.label")
            span Type
            span {{ pool.poolType.label }}
          li(v-if="pool.indication")
            span Indications
            span {{pool.indication}}
          li(v-if="pool.poolAccessType")
            span Acc&egrave;s au bassin
            span {{ pool.poolAccessType.label }}
          li(v-if="pool.accessMethodType")
            span M&ecirc;thode d&apos;acc&egrave;s
            span {{ pool.accessMethodType.label }}
          li(v-if="pool.condition>=0")
            span État du bassin
            span {{ findLabelCondition(pool.condition) }}
          li(v-if="pool.seaWeeds >= 0")
            span V&eacute;g&eacute;tation
            span {{ findLabelVegetation(pool.seaWeeds) }}
          li(v-if="pool.length")
            span Longueur
            span {{ pool.length }} m
          li(v-if="pool.width")
            span Largeur
            span {{ pool.width }} m
          li(v-if="pool.depth")
            span Profondeur
            span {{ pool.depth }} cm
          li(v-if="pool.depth && pool.length && pool.width")
            span Volume
            span {{ volume(pool) }} m³
        vs-divider
        h4.mb-1.text-primary
          feather-icon.mr-1(icon="MapPinIcon")
          | Localisation
        ul.listTab
          li(v-if="pool.address")
            span Adresse
            span {{ pool.address }}
          li(v-if="pool.postalCode")
            span Code postal
            span {{ pool.postalCode }}
          li(v-if="pool.city")
            span Ville
            span {{ pool.city }}
          li(v-if="pool.long")
            span Longitude
            span {{ pool.long }}
          li(v-if="pool.lat")
            span Latitude
            span {{ pool.lat }}
        vs-divider
        h4.mb-1.text-primary
          feather-icon.mr-1(icon="ToolIcon")
          | Options
        ul.listTab
          li(v-if="pool.contractType")
            span Type de contrat
            span {{ pool.contractType.label }}
          li(v-if="pool.waterTreatmentType")
            span Type de traitement
            span {{ pool.waterTreatmentType.label }}
          li(v-if="pool.coatingType")
            span Type de rev&ecirc;tement
            span {{ pool.coatingType.label }}
          li
            span Syst&egrave;me hors-gel
            span(v-if="pool.hasAntiFrostSystem === true") Oui
            span(v-else) Non
          li
            span Pr&eacute;sence d&apos;un rideau
            span(v-if="pool.hasCurtain === true") Oui
            span(v-else) Non
          li
            span Nage contre courant
            span(v-if="pool.hasJetSwim === true") Oui
            span(v-else) Non
          li
            span Pr&eacute;sence d&apos;un robot
            span(v-if="pool.hasCleaningRobot === true") Oui
            span(v-else) Non
          li
            span Pr&eacute;sence de barri&egrave;re avec porte
            span(v-if="pool.hasFenceWithGate === true") Oui
            span(v-else) Non
          li
            span Pompe à chaleur
            span(v-if="pool.hasHeatPump === true") Oui
            span(v-else) Non
          li
            span Bac Tampon
            span(v-if="pool.hasBufferTank === true") Oui
            span(v-else) Non
          li
            span Remplissage Automatique
            span(v-if="pool.hasAutomaticFilling === true") Oui
            span(v-else) Non
          li
            span Compteur d'eau
            span(v-if="pool.hasWaterMeter === true") Oui
            span(v-else) Non
        vs-divider
      .devices(v-if="tabActive == 'devices'")
        h4.mb-1.text-primary
          feather-icon.mr-1(icon="InfoIcon")
          | Appareils du bassin
        .text-center.flex-center.mt-5(v-if="isLoadingDevicesListByPoolId")
          .loading-bg-inner
            .loader
              .outer
              .middle
              .inner
          p(style="transform: translateY(55px)") Chargement de la liste des appareils...
        .text-center.flex-center.mt-5(
          v-else-if="devicesListByPoolId.length == 0"
        )
          feather-icon(icon="InboxIcon", size="52")
          p(style="transform: translateY(5px)") Aucun appareils.
          b-button.btn-icon(
            variant="primary",
            @click="$router.push('/new-device/'), (active = !active)",
            :disabled="archived"
          ) Ajouter un appareil
        ul.listTab(v-else)
          li(v-for="device in devicesListByPoolId", :key="device.id")
            .device-col
              span {{ device.label }} ({{ device.brand.label }})
              span {{ device.deviceType.label }}
            .d-flex.justify-content-end.w-50
              span {{ dateConvert(device.date) }}
      .products(v-if="tabActive == 'products'")
        h4.mb-1.text-primary
          feather-icon.mr-1(icon="InfoIcon")
          | Produits utilisés sur une période
        .group-total
          date-range-picker#dates(
            ref="picker",
            :show-week-numbers="true",
            :timePicker="false",
            :opens="'left'",
            :append-to-body="true",
            :locale-data="config",
            :ranges="defaultRange",
            v-model="filterRange",
            :auto-apply="true"
          )
          p(v-if="productUsedList.length > 0") Total : Public <b>{{ formatCurrency(totalPublicProductUsed) }}</b> Net  <b>{{ formatCurrency(totalNetProductUsed) }}</b>
        .text-center.flex-center.mt-5(v-if="isLoadingProductUsedList")
          .loading-bg-inner
            .loader
              .outer
              .middle
              .inner
          p(style="transform: translateY(55px)") Chargement de la liste des produits utilisés...
        .text-center.flex-center.mt-5(v-else-if="productUsedList.length == 0")
          feather-icon(icon="InboxIcon", size="52")
          p(style="transform: translateY(5px)") Aucun produit utilisé sur cette période.
        ul.listTab(v-else)
          li.d-flex.justify-content-flex-between.w-100(v-for="(productUsed, index) in productUsedList", :key="index")  
            span.w-75 {{ productUsed.label }}<br/>({{ formatCurrency(productUsed.netPrice) }} {{ productUsed.unit }})
            .d-flex.justify-content-between.w-25
              span x {{ productUsed.quantity }}
              span {{ formatCurrency(productUsed.netPrice * productUsed.quantity) }}
      .lastAnalysis(v-if="tabActive == 'lastAnalysis'")
        h4.mb-1.text-primary
            feather-icon.mr-1(icon="ClockIcon")
            | Dernières analyses
        .text-center.flex-center.mt-5(
          v-if="analysisList.length == 0"
        )
          feather-icon(icon="InboxIcon", size="52")
          p(style="transform: translateY(5px)") Aucune analyse.
        ul.listTab(v-else)
            li(v-for="(analysis, index) in analysisList", :key="index")
              span {{analysis.missionType.label}}
              span {{ analysis.value }} {{ analysis.missionType.id == 21 ? 'cm' : analysis.missionType.id == 29 ? 'm3' : '' }}<br/>{{ dateConvert(analysis.recordedDate) }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { formatCurrency } from "@/utils";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
const config = {
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
  firstDay: 1,
};
const defaultRange = {
  "Ce mois-ci": [new Date(dayjs().startOf("month").hour(1)), new Date(dayjs().endOf("month"))],
  "Mois dernier": [new Date(dayjs().subtract(1, "month").startOf("month").hour(1)), new Date(dayjs().subtract(1, "month").endOf("month"))],
  "Ce trimestre": [new Date(dayjs().startOf("quarter").hour(1)), new Date(dayjs().endOf("quarter"))],
  "Trimestre dernier": [new Date(dayjs().subtract(1, "quarter").startOf("quarter").hour(1)), new Date(dayjs().subtract(1, "quarter").endOf("quarter"))],
  "Cette année": [new Date(dayjs().startOf("year").hour(1)), new Date(dayjs().endOf("year"))],
  "Année dernière": [new Date(dayjs().subtract(1, "year").startOf("year").hour(1)), new Date(dayjs().subtract(1, "year").endOf("year"))],
  "Depuis le début": [new Date(dayjs("2020-01-01").hour(1)), new Date(dayjs().endOf("year"))],
};

export default {
  data() {
    return {
      config: config,
      defaultRange: defaultRange,
      filterRange: {
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      },
      lastAnalysis: [],
      tabActive: "pool",
      archived: false,
      optionSeaWeeds: [
        {
          label: "Aucune",
          value: 1,
        },
        {
          label: "Modérée",
          value: 2,
        },
        {
          label: "Elevée",
          value: 3,
        },
      ],
      optionConditions: [
        {
          label: "Catastrophique",
          value: 1,
        },
        {
          label: "Mauvais état",
          value: 2,
        },
        {
          label: "État Moyen",
          value: 3,
        },
        {
          label: "Bon état",
          value: 4,
        },
        {
          label: "Impéccable",
          value: 5,
        },
      ],
    };
  },
  components: {
    DateRangePicker,
  },
  watch: {
    filterRange() {
      this.fetchProductUsedOnTimeSpanByPoolId({
        poolId: this.pool.id,
        startTo: this.filterRange.startDate,
        endTo: this.filterRange.endDate,
      });
    },
    isOpenSidebarPool(val) {
      if (!val) {
        this.filterRange = {
          startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
        };
      }
    },
  },
  computed: {
    ...mapGetters([
      "customer",
      "pool",
      "analysisList",
      "isUpdatingPool",
      "compagniesList",
      "isLoadingPool",
      "isLoadingCustomer",
      "isDeletingPool",
      "isOpenSidebarPool",
      "productUsedList",
      "isLoadingProductUsedList",
      "isLoadingDevicesListByPoolId",
      "devicesListByPoolId",
    ]),
    active: {
      get() {
        return this.isOpenSidebarPool;
      },
      set(val) {
        this.$store.commit("SET_IS_OPEN_SIDEBAR_POOL", val);
      },
    },
    totalNetProductUsed() {
      let total = 0;
      this.productUsedList.forEach((productUsed) => {
        total += productUsed.netPrice * productUsed.quantity;
      });
      return total;
    },
    totalPublicProductUsed() {
      let total = 0;
      this.productUsedList.forEach((productUsed) => {
        total += productUsed.publicPrice * productUsed.quantity;
      });
      return total;
    },
  },
  created() {
    this.getLastAnalysi;
  },
  methods: {
    ...mapActions([
      "updatePool",
      "deletePool",
      "isArchivingPool",
      "archivePool",
      "restorePool",
      "fetchPool",
      "fetchPoolArchived",
      "fetchCustomer",
      "fetchProductUsedOnTimeSpanByPoolId",
      "fetchLastAnalysisByPoolId",
    ]),
    volume(pool) {
      switch (pool.poolType?.shape) {
        case "rectangulaire":
          return Math.ceil(pool.width * pool.length * (pool.depth / 100)) + "";
        case "ronde":
          var radius = pool.width / 2;
          return Math.ceil(Math.PI * Math.pow(radius, 2) * (pool.depth / 100)) + "";
        case "ovale":
          var semiMajorAxis = pool.width / 2;
          var semiMinorAxis = pool.length / 2;
          return Math.ceil(Math.PI * semiMajorAxis * semiMinorAxis * (pool.depth / 100)) + "";
        default:
          return null;
      }
    },
    dateConvert(datePull) {
      const date = new Date(datePull);
      const jour = date.getDate();
      const mois = date.getMonth() + 1;
      const annee = date.getFullYear();
      const heures = date.getHours();
      const minutes = date.getMinutes();
      const dateFormatee = `${jour < 10 ? "0" + jour : jour}/${mois < 10 ? "0" + mois : mois}/${annee} ${heures < 10 ? "0" + heures : heures}:${
        minutes < 10 ? "0" + minutes : minutes
      }`;
      return dateFormatee;
    },
    formatCurrency,
    getPoolArchivedDetails(id, archived) {
      this.archived = archived;
      if (id > 0) {
        this.fetchPoolArchived(id).then((res) => {
          this.pool = res;
        });
      } else {
        this.pool = {};
      }
    },
    findLabelVegetation(id) {
      const vegetation = this.optionSeaWeeds.find((elem) => elem.value === id);
      if (vegetation) {
        return vegetation.label;
      } else {
        return "#N/D";
      }
    },
    findLabelCondition(id) {
      const indexVegetation = this.optionConditions.findIndex((elem) => elem.value === id);
      return this.optionConditions[indexVegetation].label;
    },
    getPoolDetails(id) {
      this.archived = false;
      this.fetchPool(id).then((res) => {
        this.fetchCustomer(res.customerId);
        this.fetchLastAnalysisByPoolId(res.id);
      });
    },
    async editPool(id) {
      this.$store.commit("SET_IS_OPEN_SIDEBAR_POOL", false);
      this.$router.push({
        name: "edit-pool",
        params: {
          id: id,
          title: "Edit : " + this.pool.label,
          tips: "Editer le bassin : " + this.pool.label,
        },
      });
    },
    archivePoolLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.", {
          title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Archiver",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.archivePool(id);
            this.$store.commit("SET_IS_OPEN_SIDEBAR_POOL", false);
          }
        });
    },
    restorePoolLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.", {
          title: 'Êtes-vous sûr de vouloir restaurer "' + label + '" ?',
          size: "sm",
          okVariant: "warning",
          okTitle: "Restaurer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.restorePool(id);
            this.$store.commit("SET_IS_OPEN_SIDEBAR_POOL", false);
          }
        });
    },
    deletePoolLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: 'Êtes-vous sûr de vouloir supprimer "' + label + '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deletePool(id);
            this.$store.commit("SET_IS_OPEN_SIDEBAR_POOL", false);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-scrollable-sticky {
  position: relative;
  padding: 0 !important;
  #nav-sticky-horizontal {
    white-space: nowrap;
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      border-bottom: 1px solid var(--gray);
      margin-bottom: 0.7rem;
      li a {
        display: block;
        padding: 0.3rem 1rem;
        color: var(--body-color);
        text-decoration: none;
      }
      li a.disabled {
        color: var(--gray);
        cursor: default !important;
        .material-icons-outlined {
          color: var(--gray) !important;
        }
      }
      li a.current {
        color: var(--primary);
        font-weight: 600;
        position: relative;
        font-size: 14px;
        &:before {
          content: "";
          width: 100%;
          height: 3px;
          position: absolute;
          right: 0px;
          bottom: -3px;

          background: var(--primary) !important;
        }
        .material-icons-outlined {
          color: var(--primary) !important;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .content-scrollable-sticky {
    #nav-sticky-horizontal {
      > ul {
        display: flex;
        width: 100%;
        align-content: center;
        justify-content: center;
        align-items: center;
        li {
          flex: 1;
        }
        li a.current {
          position: relative;
          &:before {
            content: "";
            height: 3px;
            width: 100%;
            position: absolute;
            bottom: -2px;
          }
        }
      }
    }
  }
}
@media (min-height: 300px) {
  #nav-sticky-horizontal ul {
    position: sticky;
    top: 1rem;
  }
}

.group-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  #dates {
    width: 100%;
    .drp-buttons {
      display: none;
    }
  }
  p {
    margin-bottom: 0;
  }
}
span {
  word-break: break-word;
}
.products .listTab li {
  display: flex;
  justify-content: space-between;

  .group-data {
    width: 45%;
    display: flex;
    justify-content: space-between;
  }
  span {
    &:not(:first-child) {
      text-align: right;
      width: fit-content !important;
    }
    &:first-child {
      width: auto !important;
      padding-right: 1rem;
    }
  }
}

.devices {
  li {
    display: flex;
    justify-content: space-between;
    span {
      width: fit-content !important;
    }
    .device-col {
      display: flex;
      flex-direction: column;
    }
  }
}

h4 {
  display: flex;
  align-items: center;
}
</style>
